<template>
<div>
    <!-- Top Domain Details -->
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="domain-info mb-8">
                <!-- Domain -->
                <select-filter labelname="Domain" :options="domainOptions" optionsName="domainName" :value="domainSelected" @input="(newDomain) => {domainSelected = newDomain}"></select-filter>
                <!-- Date Picker -->
                <date-range-picker :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="(newFromDate) => {fromDate = newFromDate}" @inputToDate="(newToDate) => {toDate = newToDate}"></date-range-picker>
                <!-- Only Companies checkbox -->
                <only-company :onlyCompaniesValue="onlyCompanies" @input="(newonlyCompanies) => {onlyCompanies = newonlyCompanies}"></only-company>
                <!-- Update -->
                <vs-button type="filled" class="small-btn bg-actionbutton" @click="updateData()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
            </div>
        </div>
    </div>

    <div class="vx-row">
        <!-- Top Browsers -->
        <div class="vx-col w-full mb-8 lg:w-7/12">
            <single-type-multi-column-chart
                :chartTitle="'Top Browsers'"
                :chartType="'bar'"
                :chartHeight="'325'"

                :seriesNameOne="'Unique Visitors'"
                :seriesDataOne="topBrowserChartSeriesDataOne"

                :optionsXaxis="topBrowserChartOptionsXaxis"
            ></single-type-multi-column-chart>
        </div>
        <!-- Unique Visitors By Browser Family -->
        <div class="vx-col w-full mb-8 lg:w-5/12">
            <three-column-table
                :refValue="'tableDataUniqueVisitorsByBrowserFamily'"
                @refDataInput="(newRefData) => {refVisitorsByBrowserFamilyData = newRefData;}"
                :tableTitle="'Unique Visitors by Browser Family'"
                :tableRecords="uniqueVisitorBrowser"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Browser Family','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'UA_Br_Fam'"
            ></three-column-table>
        </div>
    </div>

    <div class="vx-row">
        <!-- Top Operating Systems -->
        <div class="vx-col w-full mb-8 lg:w-7/12">
            <single-type-multi-column-chart
                :chartTitle="'Top Operating Systems'"
                :chartType="'bar'"
                :chartHeight="'325'"

                :seriesNameOne="'Unique Visitors'"
                :seriesDataOne="topopareChartSeriesDataOne"

                :optionsXaxis="topopareChartOptionsXaxis"
            ></single-type-multi-column-chart>
        </div>
        <!-- Unique Visitors By OS Family -->
        <div class="vx-col w-full mb-8 lg:w-5/12">
            <three-column-table
                :refValue="'tableDataUniqueVisitorsByOSFamily'"
                @refDataInput="(newRefData) => {refVisitorsbyOSFamilyData = newRefData;}"
                :tableTitle="'Unique Visitors by OS Family'"
                :tableRecords="uniqueVisitorsByOsFamily"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Operating System Family','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'UA_OS_Fam'"
            ></three-column-table>
        </div>
    </div>

    <div class="vx-row">
        <!-- Unique Visitors By Browser Version -->
        <div class="vx-col mb-8 w-full xl:w-1/3 lg:w-1/2">
            <three-column-table
                :refValue="'tableDataUniqueVisitorsByBrowserVersion'"
                @refDataInput="(newRefData) => {refVisitorsByBrowserVersionData = newRefData;}"
                :tableTitle="'Unique Visitors by Browser Version'"
                :tableRecords="uniqueVisitorsByBrowserVersion"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Browser Version','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'UA_Br_Name'"
            ></three-column-table>
        </div>
        <!-- Unique Visitors By Browser Resolution -->
        <div class="vx-col mb-8 w-full xl:w-1/3 lg:w-1/2">
            <three-column-table
                :refValue="'tableDataUniqueVisitorsByBrowserResolution'"
                @refDataInput="(newRefData) => {refVisitorsbyBrowserResolutionData = newRefData;}"
                :tableTitle="'Unique Visitors by Browser Resolution'"
                :tableRecords="uniqueVisitorsByBrowserResolution"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Browser Resolution','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'Br_Res'"
            ></three-column-table>
        </div>
        <!-- Unique Visitors By Device Type -->
        <div class="vx-col mb-8 w-full xl:w-1/3">
            <three-column-table
                :refValue="'tableDataUniqueVisitorsByDeviceType'"
                @refDataInput="(newRefData) => {refVisitorsbyDeviceTypeData = newRefData;}"
                :tableTitle="'Unique Visitors by Device Type'"
                :tableRecords="uniqueVisitorsByDeviceType"
                :maxRecordPerPage="maxRecordPerPage"
                :columnNameArray="['Device Type','Unique Visitors','%']"
                :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
                @clickToGetDetailData="getDetailedData"
                :getDetailedDataType="'UA_Dev_Type'"
            ></three-column-table>
        </div>
    </div>

    <!-- Analytics Modal -->
    <analytics-view-information-two-modal
        :popupTitle="'Analytics - ' + category +' : '+ subCategory"
        :isOpen="analyticsActive"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :ISPIncludeMessage="ISPIncludeMessage"
        :zoneName="zoneName"
        
        :singleCardOneIcon="'UserIcon'"
        :singleCardOneSmallTitle="'Unique Visitors'"
        :singleCardOneBottomText="'From '+analyticsUniqueVisitors.startValue+' to '+ analyticsUniqueVisitors.endValue +' unique visitors'"
        :singleCardOneUpDownValue="analyticsUniqueVisitors.percentage"
        :singleCardOneUpicon="'ArrowDownIcon'"
        :singleCardOneBgcolor="'bg-first-gradient'"
        :singleCardOneMainTextColor="'text-first'"
        :singleCardOneTextColor="'text-secondary'"
        :singleCardOneBGImg="'user.svg'"

        :singleCardTwoIcon="'EyeIcon'"
        :singleCardTwoSmallTitle="'Page Views'"
        :singleCardTwoBottomText="'From '+ pageViews.startValue +' to '+ pageViews.endValue +' Page Views'"
        :singleCardTwoUpDownValue="pageViews.percentage"
        :singleCardTwoUpicon="'ArrowDownIcon'"
        :singleCardTwoBgcolor="'bg-second-gradient'"
        :singleCardTwoMainTextColor="'text-second'"
        :singleCardTwoTextColor="'text-danger'"
        :singleCardTwoBGImg="'eye.png'"

        :chartTitle="'Changes Over this Period'"
        :chartType="'line'"
        :chartHeight="'350'"
        :seriesNameOne="'Unique Visitors'"
        :seriesDataOne="lineAreaChartSplineSeriesDataOne"
        :optionsXaxis="lineAreaChartSplineOptionsXaxis"

        @clickToClosePopup="analyticsActive = false"
    ></analytics-view-information-two-modal>

</div>
</template>

<script>
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import ThreeColumnTable from '../../components/analytics/ThreeColumnTable.vue';
import AnalyticsViewInformationTwoModal from '../../components/modal/AnalyticsViewInformationTwoModal.vue';
import SingleTypeMultiColumnChart from '../../components/charts/SingleTypeMultiColumnChart.vue';

export default {
    components: {
        SelectFilter,
        DateRangePicker,
        OnlyCompany,
        ThreeColumnTable,
        AnalyticsViewInformationTwoModal,
        SingleTypeMultiColumnChart
    },
    data() {
        return {
            refVisitorsByBrowserFamilyData: null,
            refVisitorsbyOSFamilyData: null,
            refVisitorsByBrowserVersionData: null,
            refVisitorsbyBrowserResolutionData: null,
            refVisitorsbyDeviceTypeData: null,
            /* Analytics Popup  */
            analyticsActive: false,
            ISPIncludeMessage: "",
            dateStart: null,
            dateEnd: null,
            zoneName: null,
            category: null,
            subCategory: null,
            analyticsUniqueVisitors: {
                name: null,
                startValue: 0,
                endValue: 0,
                percentage: null
            },
            pageViews: {
                name: null,
                percentage: null,
                startValue: 0,
                endValue: 0
            },
            domain: [],

            /* Domain Filter */
            domainSelected: {
                domainName: "All"
            },
            //Domain dropdown options
            domainOptions: [],
            //From Date
            fromDate: null,
            //To Date
            toDate: null,
            //Only Companies
            onlyCompanies: false,

            maxRecordPerPage: 5,

            uniqueVisitorBrowser: [],
            uniqueVisitorsByOsFamily: [],
            uniqueVisitorsByBrowserVersion: [],
            uniqueVisitorsByBrowserResolution: [],
            uniqueVisitorsByDeviceType: [],

            topBrowser: [],
            //Top Browsers
            topBrowserChartSeriesDataOne: [],
            topBrowserChartOptionsXaxis: [],
            
            //Top Operating Systems
            topopareChartSeriesDataOne: [],
            topopareChartOptionsXaxis: [],
            
            //Analytics Popup
            lineAreaChartSplineSeriesDataOne: [],
            lineAreaChartSplineOptionsXaxis: [],
        };
    },
    created() {
        this.setFromDate();
        this.setToDate();
        this.getZoneListForFilter();
        this.getUniqueBrowserVisitors();
        this.getUniqueVisitorsByOsFamily();
        this.getUniqueVisitorsByBrowserVersion();
        this.getUniqueVisitorsByBrowserResolution();
        this.getUniqueVisitorsByDeviceType();
        this.getTopOperatingSystem();
        this.getTopBrowser();
    },
    methods: {
        closepopup() {
            this.popupActive = false;
        },
        //Get Domain
        async getZoneListForFilter() {
            this.$vs.loading();
            await this.axios
                .get("/ws/Zone/GetZoneListForFilter")
                .then(response => {
                    let data = response.data;
                    for (let i = 0; i < data.length; i++) {
                        this.domainOptions.push({
                            domainName: data[i]
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        updateData() {
            this.refVisitorsByBrowserFamilyData.tableDataUniqueVisitorsByBrowserFamily.currentx = 1
            this.refVisitorsbyOSFamilyData.tableDataUniqueVisitorsByOSFamily.currentx = 1
            this.refVisitorsByBrowserVersionData.tableDataUniqueVisitorsByBrowserVersion.currentx = 1
            this.refVisitorsbyBrowserResolutionData.tableDataUniqueVisitorsByBrowserResolution.currentx = 1
            this.refVisitorsbyDeviceTypeData.tableDataUniqueVisitorsByDeviceType.currentx = 1
            this.refVisitorsByBrowserFamilyData.tableDataUniqueVisitorsByBrowserFamily.searchx = ""
            this.refVisitorsbyOSFamilyData.tableDataUniqueVisitorsByOSFamily.searchx = ""
            this.refVisitorsByBrowserVersionData.tableDataUniqueVisitorsByBrowserVersion.searchx = ""
            this.refVisitorsbyBrowserResolutionData.tableDataUniqueVisitorsByBrowserResolution.searchx = ""
            this.refVisitorsbyDeviceTypeData.tableDataUniqueVisitorsByDeviceType.searchx = ""
            this.getTopBrowser();
            this.getTopOperatingSystem();
            this.getUniqueBrowserVisitors();
            this.getUniqueVisitorsByOsFamily();
            this.getUniqueVisitorsByBrowserVersion();
            this.getUniqueVisitorsByBrowserResolution();
            this.getUniqueVisitorsByDeviceType();
        },
        // get unique broweser visitors data
        async getUniqueBrowserVisitors() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetBrowserFamilyStats", input)
                .then(response => {
                    let data = response.data;
                    this.uniqueVisitorBrowser = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get unique visitors bu os family 
        async getUniqueVisitorsByOsFamily() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetOSFamilyStats", input)
                .then(response => {
                    let data = response.data;
                    this.uniqueVisitorsByOsFamily = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get unique visitor by broser version 
        async getUniqueVisitorsByBrowserVersion() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetBrowserVersionStats", input)
                .then(response => {
                    let data = response.data;
                    this.uniqueVisitorsByBrowserVersion = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get unique visitors by browser resolution 
        async getUniqueVisitorsByBrowserResolution() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetBrowserResolutionStats", input)
                .then(response => {
                    let data = response.data;
                    this.uniqueVisitorsByBrowserResolution = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get unique visitors by device type 
        async getUniqueVisitorsByDeviceType() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetDeviceTypeStats", input)
                .then(response => {
                    let data = response.data;
                    this.uniqueVisitorsByDeviceType = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get top browser
        async getTopBrowser() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetBrowserFamilyGraphStats", input)
                .then(response => {
                    let data = response.data;
                    this.topBrowser = data;
                    let x = [];
                    let v = [];
                    this.topBrowser.forEach(element => {
                        x.push(element.Key);
                        v.push(element.Value);
                    });

                    this.topBrowserChartSeriesDataOne = v
                    this.topBrowserChartOptionsXaxis = x

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get top operating system data
        async getTopOperatingSystem() {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies
            };
            await this.axios
                .post("/ws/AnalyticsDevice/GetOSFamilyGraphStats", input)
                .then(response => {
                    let data = response.data;
                    let x = [];
                    let v = [];
                    data.forEach(element => {
                        x.push(element.Key);
                        v.push(element.Value);
                    });

                    this.topopareChartSeriesDataOne = v
                    this.topopareChartOptionsXaxis = x

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get detailed data page 
        async getDetailedData(category, subcategory) {
            this.$vs.loading();
            let input = {
                Zone: this.domainSelected.domainName,
                Date_Start: this.fromDate,
                Date_End: this.toDate,
                Only_Companies: this.onlyCompanies,
                Category: category,
                SubCategory: subcategory
            };
            await this.axios
                .post("/ws/AnalyticsDetail/GetDetailData", input)
                .then(response => {
                    let data = response.data;
                    this.pageViews.name = data.Page_Views.StatName;
                    this.pageViews.startValue = data.Page_Views.StatStartValue;
                    this.pageViews.endValue = data.Page_Views.StatEndValue;
                    this.pageViews.percentage = data.Page_Views.StatChangePercent;

                    this.analyticsUniqueVisitors.name = data.Unique_Visitors.StatName;
                    this.analyticsUniqueVisitors.startValue = data.Unique_Visitors.StatStartValue;
                    this.analyticsUniqueVisitors.endValue = data.Unique_Visitors.StatEndValue;
                    this.analyticsUniqueVisitors.percentage = data.Unique_Visitors.StatChangePercent;

                    this.dateStart = data.Date_Start;
                    this.dateEnd = data.Date_End;
                    this.zoneName = data.Zone_Name;
                    this.category = data.Category;
                    this.subCategory = data.SubCategory;
                    this.ISPIncludeMessage = data.ISP_Include_Message;
                    this.analyticsActive = true;

                    let x = [];
                    let FirstGroup = [];
                    data.Unique_Visitors_Chart.forEach(element => {
                        x.push(element.x);
                        FirstGroup.push(element.FirstGroup);
                    });

                    this.lineAreaChartSplineSeriesDataOne = FirstGroup
                    this.lineAreaChartSplineOptionsXaxis = x

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    }
};
</script>
